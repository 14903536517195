import styled from "styled-components";
import { Typography, Fullscreen } from "components/UI";
import IconBase from "icons";


export const Container = styled.div`
        color: ${props => (props.theme.colors.text.default)};
`;

export const ProjectButton = styled.button`
        color: ${props => (props.theme.colors.text.default)};
        height: 50px;
        width: 100%;
        background-color: transparent;
        :hover {
                background-color: rgba(0,0,0,0.04)
        }
        display: flex;
        justify-content: flex-start;
        align-items: center;
`;

export const ProjectChooseButton = styled.button`
        display:flex;
        flex-direction: column;
        height: 60px;
        width: 100%;
        background-color: transparent;
        :hover {
                background-color: rgba(0,0,0,0.04)
        }
        padding: 15px;
`;

export const ProjectName = styled(Typography).attrs((props) => ({
        size: "m"
}))`
overflow:hidden; 
white-space:nowrap; 
text-overflow: ellipsis;
max-width: 345px;
`;

export const Icon = styled(IconBase).attrs((props) => ({
        name: "angle-small-down",
        size: "24px"
}))`
flex-shrink: 0;
`;

export const CheckedIcon = styled(IconBase).attrs((props) => ({
        name: "solid solid circle-check",
        size: "20px",
        color: props.theme.colors.text.success,
}))`
border-radius: 20px;
box-shadow: 0px 0px 5px ${props => props.color};
`;

export const PlusIcon = styled(IconBase).attrs((props) => ({
        name: "plus-small",
        size: "30px",
        color: props.theme.colors.text.default
}))`
`;

export const FullscreenContainer = styled(Fullscreen)`
> * {
        :last-child {
                display: flex;
                align-items: center;
                border-top: solid 1px ${props => (props.theme.colors.border.default)};
        }
}
`;

export const ProjectsContainer = styled.div`
width: 400px;
max-height: 400px;
overflow: scroll;
> * {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: solid 1px ${props => (props.theme.colors.border.default)};
        :last-child {
                border-bottom: none;
                justify-content: center;
        }
}
`;

export const ProjectNameContainer = styled.div`
display: flex;
align-items: center;
justify-content: space-between;
width:100%;
`;

export const NewProjectContainer = styled.div`
display: flex;
align-items: center;
width:100%;
`;

export const ProjectCreatedAt = styled(Typography).attrs((props) => ({
        size: "xs",
        color:"light",
        weight:"regular"
}))`
width: 100%;
text-align: left;
`;