//LIBRARIES
import React from "react";

//STYLEDCOMPONENTS
import {
  StyledTextField as TextFieldBase,
  StyledTextFieldContainer as TextFieldContainer,
  StyledBorder as Border,
} from "./style";

function TextField(props) {
  const {
    readOnly,
    children,
    valid,
    onChange,
    onBlur,
    onFocus,
    type,
    button,
    placeholder,
    required,
    name,
    value,
    innerRef,
    ...other
  } = props;
  const [focused, setFocused] = React.useState(false);
  function handleOnFocus(event) {
    onFocus && onFocus(event);
    setFocused(true);
  }

  function handleOnBlur(event) {
    onBlur && onBlur(event);
    setFocused(false);
  }
  return (
    <Border valid={valid} focused={focused && !readOnly}>
      <TextFieldContainer valid={valid} {...other}>
        <TextFieldBase
          readOnly={readOnly}
          value={value}
          valid={valid}
          onChange={onChange}
          onBlur={(e) => handleOnBlur(e)}
          onFocus={(e) => handleOnFocus(e)}
          type={type}
          name={name}
          button={button ? true : false}
          placeholder={placeholder}
          required={required}
          ref={innerRef}
       />
        {button}
      </TextFieldContainer>
    </Border>
  );
}

export default TextField;
