import { useEffect, useState } from "react";

export function useCtrlKeyPressed(key, action) {
  useEffect(() => {
    function onKeyDown(e) {
      if ((e.key === key || e.key.toLowerCase() === key) && (e.ctrlKey || e.metaKey)) {
        action();
      }
    }
    window.addEventListener("keydown", onKeyDown);
    return () => window.removeEventListener("keydown", onKeyDown);
  });
}

export function useKeyPressed(key, action) {
  useEffect(() => {
    function onKeyDown(e) {
      if ((e.key === key || e.key.toLowerCase() === key) && !e.ctrlKey && !e.shiftKey && !e.metaKey) action();
    }
    window.addEventListener("keydown", onKeyDown);
    return () => window.removeEventListener("keydown", onKeyDown);
  });
}

export function useKeyPressAndRelease(key, pressAction, releaseAction) {
  const [keyPressed, setKeyPressed] = useState(false)

  useEffect(() => {
    function onKeyDown(e) {
      if ((e.key === key || e.key.toLowerCase() === key) && !keyPressed) {
        pressAction()
        setKeyPressed(true)
      };
    }
    function onKeyUp(e) {
      if (e.key === key || e.key.toLowerCase() === key) {
        releaseAction()
        setKeyPressed(false)
      };
    }

    window.addEventListener("keydown", onKeyDown);
    window.addEventListener("keyup", onKeyUp);
    return () => {
      window.removeEventListener("keydown", onKeyDown);
      window.removeEventListener("keyup", onKeyUp);
    };
  });
}