//LIBRARIES
import React, {useState} from 'react';
import styled from "styled-components";
import {InputBase, IconButton} from 'components/UI';

export const StyledIconButton = styled(IconButton)`
  height: 100%;
  width: 40px;
  color: ${(props) => props.theme.colors.text.light};
  border-radius: 0px 4px 4px 0px;
  background-color:  ${(props) => !props.focused ?  `rgba(0, 0, 0, 0.025)`:`rgba(255, 255, 255, 0.5)` };
  border-bottom:  ${(props) => !props.focused ?  `2px solid white` : `2px solid transparent`};
  transition: background-color 0.3s ease-in-out;

  :hover {
    background-color: ${props=>(!props.disabled && !props.focused ? "rgba(0,0,0,0.05)": "rgba(0,0,0,0.01)")}; 
  }

`;

function validatePassword(password) {
  return ((password ? true : false))
}

function Password(props) {
  const {valid, setInput, label, input, focus, ...other } = props;
  const [visible, setVisible] = useState(false);

  function changeVisibility(){
    setVisible(!visible);
  }

  let buttonComponent = <StyledIconButton onClick={changeVisibility} icon={!visible ? "eye" : "eye-slash"} />




  function handleOnChange(event){
    let validPassword = validatePassword(event.target.value);
    setInput({value:event.target.value,valid:validPassword});
  }

  return (
    <InputBase type={visible ? "text" : "password"} value={input} valid={true} label={label}  handleOnChange={handleOnChange} buttonComponent={buttonComponent} {...other}/>
  );
}

export default Password;

// "You will have to verify that you are the owner of this password account":
// "* Please enter a valid password."