//LIBRARIES
import React, {useState} from 'react';
import InputBasePresentational from './presentational'

function InputBase(props) {
  const {valid, handleOnChange, bottomLabel, label, textField, type, topRightLabelComponent, buttonComponent, showValidIcon, value,...other } = props;
  const [focused, setFocused] = useState(false);

  function onBlur(){
    setFocused(false)
  }

  function onFocus(){
    setFocused(true)
  }

if(buttonComponent)
  var clonedButtonComponentWithMoreProps = React.cloneElement(
    buttonComponent, 
    { focused: focused }
);

  return (
    <InputBasePresentational valid={valid === undefined ? true : valid} focused={focused} value={value} label={label} type={type} showValidIcon={showValidIcon} handleOnChange={handleOnChange} buttonComponent={buttonComponent && clonedButtonComponentWithMoreProps} topRightLabelComponent={topRightLabelComponent} onBlur={onBlur} onFocus={onFocus} bottomLabel={bottomLabel} {...other}/>
  );
}

export default InputBase;

// "You will have to verify that you are the owner of this email account":
// "* Please enter a valid email."

