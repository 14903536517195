//LIBRARIES
import React, { useState, useEffect } from "react";
import FormPresentational from "./presentational";

function Form(props) {
  const { onSignUpClick, loading } = props;
  const [inputs, setInputs] = useState({
    email: { value: "", valid: null },
    username: { value: "", valid: null },
    firstName: { value: "", valid: null },
    lastName: { value: "", valid: null },
    password: { value: "", valid: null },
    confirmPassword: { value: "", valid: null },
  });
  
  const [valid, setValid] = useState(false);
  let controlValid = true;

  for (const [,value] of Object.entries(inputs)) {
    if (!value.valid) controlValid = false;
  }

  useEffect(() => {
    setValid(controlValid ? true : false);
  }, [controlValid]);

  useEffect(() => {
    if (inputs["confirmPassword"].value !== inputs["password"].value) {
      if (inputs["confirmPassword"].valid)
        setInputs({
          ...inputs,
          confirmPassword : { ...inputs["confirmPassword"], valid: false },
        });
    } else {
      if (!inputs["confirmPassword"].valid)
        setInputs({
          ...inputs,
          confirmPassword: { ...inputs["confirmPassword"], valid: true },
        });
    }
  }, [inputs]);



  const handleFieldChange = (fieldId, input) => {
    setInputs({ ...inputs, [fieldId]: input });
  };

  function handleOnSignUpClick(captchaToken) {
    onSignUpClick(
      inputs.email.value,
      inputs.username.value,
      inputs.firstName.value,
      inputs.lastName.value,
      inputs.password.value,
      inputs.confirmPassword.value,
      captchaToken
    );
  }

  return (
    <FormPresentational
      loading={loading}
      valid={valid}
      inputs={inputs}
      handleOnSignUpClick={handleOnSignUpClick}
      handleFieldChange={handleFieldChange}
    />
  );
}

export default Form;
