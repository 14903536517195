//LIBRARIES
import React from 'react';
import PropTypes from 'prop-types';
import ReactPlayer from "react-player";

//STYLEDCOMPONENTS
import { 
  StyledVideo as VideoBase,
  StyledVideoContainer as VideoContainer,
} from "./style";

import {
  Skeleton,
} from "components/UI";
  
function Video(props) {
  const { loading, url } = props;
  return (
    <VideoContainer>
      <VideoBase>
        {!loading ? (
          <ReactPlayer
            style={{ position: "absolute" }}
            width="100%"
            height="100%"
            controls={true}
            url={url}
          />
        ) : (
          <Skeleton style={{ position: "absolute" }} height={"100%"} />
        )}
      </VideoBase>
    </VideoContainer>
  );
}
 
Video.propTypes = {
  name: PropTypes.string
};

export default Video;