import { Container } from "./style";
import React from "react";

import PropTypes from "prop-types";

const propTypes = {
  title: PropTypes.string,
  flexGrow: PropTypes.number,
  withSearch: PropTypes.bool,
};

function NeumoContent({ children, title, flexGrow, withSearch, ...props }) {
  return (
        <Container {...props} variant="sunk">
          {children}
        </Container>
  );
}

NeumoContent.propTypes = propTypes;

export default NeumoContent;
