//LIBRARIES
import React, { useState, useEffect } from "react";
import FormPresentational from "./presentational";
import { useSelector } from "react-redux";


function Form(props) {
  const { onSignInClick, toggleForgotPassword, toggleEmailVerification, loading, disabled} = props;
  const [inputs, setInputs] = useState({
    username: { value: "", valid: false },
    password: { value: "", valid: false },
  });
  const [valid, setValid] = useState(false);
  const signInErrors = useSelector(store => store.user.auth.error);
  let controlValid = true;

  for (const [, value] of Object.entries(inputs)) {
    if (!value.valid) controlValid = false;
  }

  useEffect(() => {
    setValid(controlValid ? true : false);
  }, [controlValid]);

  
  const handleFieldChange = (fieldId, input) => {
    setInputs({ ...inputs, [fieldId]: input });
  };

  function handleOnSignInClick() {
    onSignInClick(inputs.username.value, inputs.password.value);
  }

  return (
    <FormPresentational
      loading={loading}
      valid={valid}
      inputs={inputs}
      toggleForgotPassword={toggleForgotPassword}
      toggleEmailVerification={toggleEmailVerification}
      handleOnSignInClick={handleOnSignInClick}
      handleFieldChange={handleFieldChange}
      signInErrors={signInErrors}
      disabled={disabled}
    />
  );
}

export default Form;
