import * as Types from "./types";
import update from "immutability-helper";
import { v4 as uuidv4 } from 'uuid';

const initialState = {
  scale: 1,
  loadingExample: false,
  exampleLoaded: false,
  id: undefined,
  fetching: false,
  fetched: false,
  submitting: false,
  submitted: false,
  skipping:false,
  name: "",
  labelsImage: null,
  previousExample: {
    loading: false,
    loaded: false,
    id: undefined,
    name: "",
    image: undefined,
    remoteImageURL: undefined,
    labels: [],
  },
  nextExample: {
    loading: false,
    loaded: false,
    id: undefined,
    name: "",
    image: undefined,
    remoteImageURL: undefined,
    labels: [],
  },
  labelClassNameChange: {
    changing: false,
    labelId: undefined,
  },
  imageURL: undefined,
  remoteImageURL: undefined,
  image: { width: 0, height: 0 },
  clipboard: null,
  labels: [],
  labelsVisible: true,
  selectedLabelId: null,
  brightness: 0,
  history: { undo: [], redo: [] },
  unsavedChanges:false,
  discardDirection:'',
  number:1,
  error: "",
};

function findLabelIndex(labels, labelId) {
  const sameId = (element) => element.id === labelId;
  return labels.findIndex(sameId);
}

function ImageDatasetToolExampleReducer(state = initialState, action) {
  switch (action.type) {
    case Types.EXAMPLE_INIT:
      return initialState;

    case Types.EXAMPLE_LOAD:
      return {
        ...state,
        loadingExample: true,
        exampleLoaded: false,
        error: "",
      };

    case Types.EXAMPLE_LOAD_END:
      return {
        ...state,
        loadingExample: false,
        exampleLoaded: true,
        error: "",
      };

    case Types.EXAMPLE_FETCH_START:
      return {
        ...state,
        fetching: true,
        fetched: false,
      };

    case Types.EXAMPLE_FETCH_END:
      return {
        ...state,
        fetching: false,
        fetched: true,
      };

    case Types.EXAMPLE_REQUEST:
      return {
        ...initialState,
        loadingExample: true,
        exampleLoaded: false,
        error: "",
      };

    case Types.EXAMPLE_REQUEST_SUCCESS:{
      return {
        ...state,
        loadingExample: false,
        exampleLoaded: true,
        submitted:false,
        id: action.payload.id,
        name: action.payload.name,
        number: action.payload.number,
        imageURL: action.payload.imageURL,
        remoteImageURL: action.payload.remoteImageURL,
        selectedLabelId: null,
        brightness: 0,
        history: { undo: [], redo: [] },
        unsavedChanges:false,
        discardDirection:'',
        error: "",
      };
    }

    case Types.EXAMPLE_REQUEST_FAILURE:
      return {
        ...state,
        loadingExample: false,
        exampleLoaded: false,
        error: action.payload.error,
      };

    case Types.EXAMPLE_DELETE:
      return {
        ...state,
        deleting: true,
        deleted: false,
        error: "",
      };

    case Types.EXAMPLE_DELETE_SUCCESS:
      return {
        ...state,
        deleting: false,
        deleted: true,
        error: "",
      };

    case Types.EXAMPLE_DELETE_FAILURE:
      return {
        ...state,
        deleting: false,
        deleted: false,
        error: action.payload.error,
      };

    case Types.PREVIOUS_EXAMPLE_REQUEST:
      return {
        ...state,
        previousExample: {
          loading: true,
          loaded: false,
          id: undefined,
          name: "",
          number: 1,
          image: undefined,
          labels: [],
        },
      };

    case Types.PREVIOUS_EXAMPLE_REQUEST_SUCCESS:
      return {
        ...state,
        previousExample: {
          loading: false,
          loaded: true,
          id: action.payload.id,
          name: action.payload.name,
          number: action.payload.number,
          image: action.payload.imageURL,
          remoteImageURL: action.payload.remoteImageURL,
          labels: action.payload.labels,
        },
        unsavedChanges:false,
        discardDirection:''
      };

    case Types.NEXT_EXAMPLE_REQUEST:
      return {
        ...state,
        nextExample: {
          loading: true,
          loaded: false,
          id: undefined,
          name: "",
          number: 1,
          image: undefined,
          labels: [],
        },
      };

    case Types.NEXT_EXAMPLE_REQUEST_SUCCESS:
      return {
        ...state,
          nextExample: {
          loading: false,
          loaded: true,
          id: action.payload.id,
          name: action.payload.name,
          number: action.payload.number,
          image: action.payload.imageURL,
          remoteImageURL: action.payload.remoteImageURL,
          labels: action.payload.labels,
        },
        unsavedChanges:false,
        discardDirection:''
      };

    case Types.EXAMPLE_SUBMIT:
      return {
        ...state,
        submitting: true,
        submitted: false,
      };

    case Types.EXAMPLE_SUBMIT_SUCCESS:
      return {
        ...state,
        submitting: false,
        submitted: true,
      };

    case Types.EXAMPLE_SUBMIT_FAILURE:
      return {
        ...state,
        submitting: false,
        submitted: false,
        error: action.payload.error,
      };

    case Types.EXAMPLE_SKIP:
      return {
        ...state,
        skipping:true
      }

    case Types.EXAMPLE_SKIP_SUCCESS:
      return {
        ...state,
        skipping:false
      }

    case Types.EXAMPLE_SKIP_FAILURE:
      return {
        ...state,
        skipping:false,
        error:action.payload.error
      }

    case Types.SET_IMAGE_SIZE:
      return {
        ...state,
        image: {
          ...state.image,
          width: action.payload.imageWidth,
          height: action.payload.imageHeight,
        },
      };

    case Types.SET_SCALE:
      return {
        ...state,
        scale: action.payload.scale,
      };

    case Types.SET_LABELS: {
      return {
        ...state,
        labels: action.payload.labels
      };
    }

    case Types.ADD_LABEL: {
      const newLabelId = uuidv4();
  
      return {
        ...state,
        labels: [
          ...state.labels,
          { ...action.payload.label, id: newLabelId},
        ],
        selectedLabelId:newLabelId
      };
    }



    case Types.REPLACE_LAST_LABEL: {
     
      const LABELS_LENGHT = state.labels.length;
      const lastLabel = state.labels[LABELS_LENGHT-1];

      return {
        ...state,
        labels: [
          ...state.labels.slice(0, -1),
          { ...action.payload.label, id:lastLabel.id, classNameId: lastLabel.classNameId},
        ],
        selectedLabelId: action.payload.label.id
      };
    }


    case Types.SET_SELECTED_LABEL:
      console.log(
        "SET_SELECTED_LABEL",
        action.payload.selectedLabelId,
      )
      return {
        ...state,
        selectedLabelId: action.payload.selectedLabelId,
      };

    case Types.SET_LABELS_IMAGE:

      return {
        ...state,
        labelsImage: action.payload.labelsImage,
      };

    case Types.SET_LABEL_TEXT:
      let labelIndex = findLabelIndex(state.labels, action.payload.labelId);
      return update(state, {
        labels:{
          [labelIndex]: {
            text:{$set: action.payload.text}
          }
        }
      });

      case Types.REPLACE_LABEL_BY_ID: {
        const labelIndex = findLabelIndex(state.labels, action.payload.id);
        return update(state, {
          labels:{
            [labelIndex]: {
              $set: action.payload.label
            }
          }
        });
      }


    case Types.BRIGHTNESS_SET:
      return {
        ...state,
        brightness: action.payload.brightness,
      };

case Types.ADD_UNDO:
  return update(state, {
    history: {
      undo: { $push: [{ labels: state.labels, labelsImage: state.labelsImage }] },
      redo: { $set: [] },
    },
  });

case Types.UNDO: {
  const undoLength = state.history.undo.length;
  let newState = undoLength > 0 && { ...state.history.undo[undoLength - 1] };

  if (newState) {
    return update(state, {
      history: {
        undo: { $splice: [[undoLength - 1, 1]] },
        redo: { $push: [{ labels: state.labels, labelsImage: state.labelsImage }] },
      },
      labels: { $set: newState.labels },
      labelsCanvas: { $set: newState.labelsImage },
    });
  } else return state;
}

case Types.REDO: {
  const redoLength = state.history.redo.length;
  let newState = redoLength > 0 && { ...state.history.redo[redoLength - 1] };

  if (newState) {
    return update(state, {
      history: {
        undo: { $push: [{ labels: state.labels, labelsImage: state.labelsImage }] },
        redo: { $splice: [[redoLength - 1, 1]] },
      },
      labels: { $set: newState.labels },
      labelsImage: { $set: newState.labelsImage },
    });
  } else return state;
}

    case Types.COPY_LABEL: {
      let copiedLabel = {
        ...state.labels[findLabelIndex(state.labels, action.payload.labelId)],
      };
      return {
        ...state,
        clipboard: copiedLabel,
      };
    }

    case Types.PASTE_LABEL: {
      if (state.clipboard) {
        let copiedLabel = { ...state.clipboard };
        copiedLabel.id = uuidv4();
        return {
          ...state,
          labels: [...state.labels, copiedLabel],
          selectedLabelId: copiedLabel.id
        };
      } else return state;
    }

    case Types.PASTE_PREVIOUS_LABELS: {
      return {
        ...state,
        labels: [...state.previousExample.labels]
      };
    }

    case Types.DELETE_LABEL: {
      var labelsFiltered = state.labels.filter(function (label, index, arr) {
        return label.id != action.payload.labelId;
      });
      return {
        ...state,
        labels: labelsFiltered,
      };
    }

    case Types.REJECT_AUTOLABELER_LABELS: {
      return {
        ...state,
        labels: state.history.undo[0],
      };
    }

    case Types.ACCEPT_AUTOLABELER_LABELS: {
      var labelsModified = state.labels.map(function (label) {
        return {...label, autolabeler:false};
      });
      return {
        ...state,
        labels: labelsModified,
      };
    }

    case Types.BRING_TO_FRONT_LABEL: {
      let labelIndex = findLabelIndex(state.labels, action.payload.labelId);
      if (labelIndex < state.labels.length - 1) {
        let newLabels = [...state.labels];
        let labelToFront = { ...state.labels[labelIndex] };
        newLabels.splice(labelIndex, 1);
        newLabels = [...newLabels, labelToFront];
        return {
          ...state,
          labels: newLabels,
        };
      } else return state;
    }

    case Types.SEND_TO_BACK_LABEL: {
      let labelIndex = findLabelIndex(state.labels, action.payload.labelId);
      if (labelIndex > 0) {
        let newLabels = [...state.labels];
        let labelToBack = { ...state.labels[labelIndex] };
        newLabels.splice(labelIndex, 1);
        newLabels = [labelToBack, ...newLabels];
        return {
          ...state,
          labels: newLabels,
        };
      } else return state;
    }

    case Types.SET_LABEL_VISIBLE: {
      let labelIndex = findLabelIndex(state.labels, action.payload.labelId);
      return update(state, {
        labels:{
            [labelIndex]: {$toggle: ['visible']}
          }
      })
    }

    case Types.SET_LABELS_VISIBLE: {
      return { ...state, labelsVisible: !state.labelsVisible };
    }

    case Types.CHANGE_LABEL_CLASSNAME: {
      let labelIndex = findLabelIndex(state.labels, action.payload.labelId);

      if (labelIndex >= 0) {
        return update(state, {
          labels: {
            [labelIndex]: {
              classNameId: { $set: action.payload.classNameId },
            },
          },
          labelClassNameChange: {
            changing: { $set: false },
            labelId: { $set: undefined },
          },
        });
      }
      return {
        ...state,
        labelClassNameChange: {
          changing: false,
          labelId: undefined,
        },
      };
    }

    case Types.LABEL_CLASSNAME_CHANGE_START:
      return {
        ...state,
        labelClassNameChange: {
          changing: true,
          labelId: action.payload.labelId,
        },
      };

    case Types.LABEL_CLASSNAME_CHANGE_END:
      return {
        ...state,
        labelClassNameChange: {
          changing: false,
          labelId: undefined,
        },
      };
    
    case Types.EXAMPLE_UNSAVED_CHANGES_SET:
      return {
        ...state,
        unsavedChanges:action.payload.unsavedChanges,
        discardDirection:action.payload.discardDirection
      }

    default:
      return state;
  }
}

export default ImageDatasetToolExampleReducer;
