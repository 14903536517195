import React from 'react';
import ScreenRoot from './screens/root';
import { store, persistor } from './state/store';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import './App.css';

function App() {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <ScreenRoot />
      </PersistGate>
    </Provider>
  )
}

export default App;