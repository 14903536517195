import React from "react";

import * as S from "./style";
import MuiTextField from "./MuiTextField";

function Calendar(props) {
  const { className, ...other } = props;
  // const [open, setOpen] = useState(false);


  return (
    <S.MuiCalendar
      components={{
        OpenPickerIcon: S.OpenPickerIcon,
        SwitchViewIcon: S.SwitchViewIcon,
      }}
      PaperProps={{
        component: S.Paper,
      }}
   {...other}
      renderInput={(params) => {
        return (
          <MuiTextField
            {...params}
            className={className}
            helperText={null} 

            // placeholder={undefined} label={undefined}
            // onFocus={(e) => setOpen(true)}
            // onBlur={(e) => setOpen(false)}
          />
        );
      }}
    />
  );
}

export default Calendar;
