import * as types from './types';
import * as namespaces from './namespaces';
import * as states from './states';
import * as actions from './actions';
import { notify } from 'state/App/Notifications/actions';
import { handleTrainingSessionExperimentAPIMessage } from 'components/Project/Training/TrainingSession/ExperimentAPI/state/actions';
import { setTrainingSessionExperimentMachineStatus } from 'components/Project/Training/TrainingSession/state/actions';
import * as experimentAPITypes from 'components/Project/Training/TrainingSession/ExperimentAPI/state/types';
import * as trainingSessionTypes from 'components/Project/Training/TrainingSession/state/types';
import WebsocketHeartbeatJs from 'websocket-heartbeat-js';
import * as subfields from './subfields';
import { fetchMachinesInProject } from 'components/Project/Machines/MachineList/state/actions';


const WebsocketMiddleware = () => {
  let socket = null;

  const onOpen = store => (event) => {
    store.dispatch(actions.connected());
  };

  const onClose = store => () => {
    store.dispatch(actions.closed());
  };

  const onReconnect = store => () => {
    store.dispatch(actions.reconnect());
  }

  const onError = store => (error) => {
    store.dispatch(actions.error(error));
  };

  const onMessage = store => (event) => {
    if (event.data !== 'pong') {
      const payload = JSON.parse(event.data);
      console.log(payload);
      switch (payload.namespace) {
        case namespaces.TRAINING_SESSION:
          switch (payload.subfield) {
            case subfields.OBJECT_DETECTION:
            case subfields.OCR:
              console.log(payload.content)
              store.dispatch(handleTrainingSessionExperimentAPIMessage(payload.content));
              break;
            default:
              console.log('UNKNOWN SUBFIELD:', payload.subfield);
          }
          break;
        case namespaces.NOTIFICATION:
          const notificationContent = payload.content;
          console.log('NOTIFICATION CONTENT:', notificationContent);
          store.dispatch(notify(notificationContent.type, notificationContent.message));
          break;
        case namespaces.STATE_CHANGE:
          const stateContent = payload.content;
          console.log('STATE CHANGE CONTENT:', stateContent);
          if (stateContent.type === states.MACHINE_CONNECTED || stateContent.type === states.MACHINE_DISCONNECTED) {
            const trainingSession = store.getState().project.training.session.main;

            if (trainingSession.experiment.machine && trainingSession.experiment.machine.uuid === stateContent.state.machine_uuid) {
              store.dispatch(setTrainingSessionExperimentMachineStatus(stateContent.type === states.MACHINE_CONNECTED));
            } else {
              const projectKey = store.getState().project.main.payload.key;
              store.dispatch(fetchMachinesInProject(projectKey));
            }
          }else{
            if (stateContent.notification) {
              store.dispatch(notify(stateContent.notification.type, stateContent.notification.message));
            }
          }
          break;
        default:
          console.log(payload);
          break;
      }
    }
  };

  return store => next => (action) => {
    const experimentAPI = store.getState().project.training.session.experimentAPI;
    const trainingSession = store.getState().project.training.session.main;

    switch (action.type) {
      case types.WEBSOCKET_CONNECT:
        if (socket !== null) {
          socket.close();
        }

        const token = store.getState().user.auth.token;
        const project_id = store.getState().project.main.payload.id;
        const options = {
          url: `${process.env.REACT_APP_WS_API_URL}/client/?token=${token}&project_id=${project_id}`,
          pingTimeout: 60000,
          pongTimeout: 60000,
          reconnectTimeout: 30000,
          pingMsg: 'ping'
        }

        socket = new WebsocketHeartbeatJs(options);

        socket.onmessage = onMessage(store);
        socket.onclose = onClose(store);
        socket.onopen = onOpen(store);
        socket.onerror = onError(store);
        socket.onreconnect = onReconnect(store);

        const waitForConnection = function (callback, interval) {
          if (socket.ws.readyState === 1) {
            callback();
          } else {
            setTimeout(function () {
              waitForConnection(callback, interval);
            }, interval);
          }
        };

        socket.safesend = function (message, callback) {
          waitForConnection(function () {
            socket.ws.send(message);
            if (typeof callback !== 'undefined') {
              callback();
            }
            console.log('SENT SAFE MESSAGE');
          }, 300);
        };

        return next(action);

      case types.WEBSOCKET_DISCONNECT:
        if (socket !== null) {
          socket.close();
        }
        socket = null;
        break;

      case experimentAPITypes.TRAINING_SESSION_EXPERIMENT_API_START:
        socket.safesend(JSON.stringify({
          namespace: namespaces.TRAINING_SESSION,
          content: {
            subfield: experimentAPI.configuration.subfield,
            command: 'START',
            args: action.payload.configuration
          }
        }));
        return next(action);

      case experimentAPITypes.TRAINING_SESSION_EXPERIMENT_API_STOP:
        socket.safesend(JSON.stringify({
          namespace: namespaces.TRAINING_SESSION,
          content: {
            subfield: experimentAPI.configuration.subfield,
            command: 'STOP',
            args: {
              to_another: false,
              experiment_id: trainingSession.selectedExperimentId,
              machine_uuid: experimentAPI.configuration.machine_uuid
            }
          }
        }));
        return next(action);

      case experimentAPITypes.TRAINING_SESSION_EXPERIMENT_API_STOP_ANOTHER_SESSION:
        socket.safesend(JSON.stringify({
          namespace: namespaces.TRAINING_SESSION,
          content: {
            subfield: experimentAPI.configuration.subfield,
            command: 'STOP',
            args: {
              to_another: true,
              machine_uuid: experimentAPI.configuration.machine_uuid
            }
          }
        }));
        return next(action);

      case trainingSessionTypes.TRAINING_SESSION_SELECTED_EXPERIMENT_SET:
        console.log('SOCKET STATE:', socket.ws.readyState);
        socket.safesend(JSON.stringify({
          namespace: namespaces.TRAINING_SESSION,
          content: {
            command: 'TRAINING_EXPERIMENT_VIEW',
            experiment_id: action.payload.experimentId
          }
        }));
        return next(action);

      default:
        return next(action);
    }
  };
};

export default WebsocketMiddleware();