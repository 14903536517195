import styled from "styled-components";
import {
  Stepper as BaseStepper,
  Step as BaseStep,
  StepLabel as BaseStepLabel,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
const spread = 4;
const blurLength = 10;



export const StepLabel = styled(BaseStepLabel).attrs((props) => ({}))`

 && .MuiStepLabel-label  {
  font-size: ${(props) => props.$small ? props.theme.fonts.body.s.size : '1.15rem'};
  font-weight: ${(props) => props.$active ? props.theme.fonts.weight.bold : props.theme.fonts.weight.regular};
  ${props => props.$small && `margin-top: 8px;`}
 }

  color: ${(props) =>
    props.$active || props.completed
      ? props.theme.colors.colorful.primary
      : props.theme.colors.bg.default};
`;

export const Check = styled(CheckIcon).attrs((props) => ({}))`
  height: 18px;
`;

export const StepIcon = styled.div`
  height: 26px;
  width: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;

  box-shadow: inset -${spread}px -${spread}px ${blurLength}px 0
      ${(props) => props.theme.colors.boxShadow.neumo.light},
    inset ${spread}px ${spread}px ${blurLength}px 0
      ${(props) => props.theme.colors.boxShadow.neumo.contrast};

  box-shadow: ${(props) =>
    props.$active || props.completed ? `0px 0px 10px 0px ${props.theme.colors.colorful.primary}` : `inset -${spread}px -${spread}px ${blurLength}px 0 ${props.theme.colors.boxShadow.neumo.light}, inset ${spread}px ${spread}px ${blurLength}px 0 ${props.theme.colors.boxShadow.neumo.contrast}`};

  background-color: ${(props) => props.$active || props.completed ? props.theme.colors.colorful.primary : props.theme.colors.bg.default};

  color: ${(props) => props.$active || props.completed ? "white" : props.theme.colors.text.light};

  transition: 400ms linear;
`;



export const Step = styled(BaseStep).attrs((props) => ({}))`
  &&.MuiStepLabel-label {
    font-weight: ${(props) => props.$active  ? props.theme.fonts.weight.medium  : props.theme.fonts.weight.thin};
    color: ${(props) =>  props.theme.colors.text.default};
    transition: 200ms linear;
  }

`;

export const Stepper = styled(BaseStepper).attrs((props) => ({}))`

  font-weight: ${(props) => props.theme.fonts.weight.bold};
  /* background-color: ${(props) => props.theme.colors.button.default}; */

  && .MuiStepLabel-iconContainer {
      > * {
        font-size: 16px;
      }


  }

  background-color: transparent;
  ${(props) =>
    props.$small ?
    `
    .MuiStepConnector-root {
      display: none;
    }
    && .MuiStepLabel-iconContainer {
      > * {
        height: 16px;
        width: 16px;
        font-size: 10px;
      }

      .MuiSvgIcon-root {
        height: 10px;
      }
  }
  `:
  `
    .MuiStepConnector-root {
      display: none;
    }
    && .MuiStepLabel-iconContainer {
      > * {
        height: 25px;
        width: 25px;
        font-size: 13px;
        margin-right: 5px;
      }

      .MuiSvgIcon-root {
        height: 15px;
      }
  }
  `
  };
`;
