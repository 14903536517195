import React from "react";


//STYLEDCOMPONENTS
import * as S from "./style";

function Stepper(props) {
  const { children, small, ...others } = props;
  console.log('STEPPER SMALL:', small);
  return (
    <S.Stepper {...others} $small={small}
      >
      {children}
    </S.Stepper>
  );
}

export default Stepper;


function StepIconComponent(props) {
  const { active, completed, icon } = props;

  return (
    <S.StepIcon $active={active} completed={completed}
    >
      {completed ? <S.Check /> : <div>{icon}</div>}
    </S.StepIcon>
  );
}

function Step(props) {
        const { children, ...others } = props;
        return (
          <S.Step {...others} 
            >
            {children}
          </S.Step>
        );
}

function StepLabel(props) {
        const { children, active, small, ...others } = props;

        return (
          <S.StepLabel {...others} $active={active} StepIconComponent={StepIconComponent} $small={small}
            >
            {children}
          </S.StepLabel>
        );
}
      
export { Step, StepLabel};
