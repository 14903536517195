//LIBRARIES
import React from 'react';
import PropTypes from 'prop-types';

//STYLEDCOMPONENTS
import { 
  StyledContainer as Container, 
} from "./style";

  
function CardWithButton(props) {
   const { children } = props;
  return (
    <Container>
      {children}
    </Container>
  );
}
 
CardWithButton.propTypes = {
  name: PropTypes.string
};

export default CardWithButton;