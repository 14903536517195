//LIBRARIES
import React from "react";
import { useDispatch, useSelector } from 'react-redux';
//STYLEDCOMPONENTS
import * as S from "./style";
import { fetchProject } from "components/Project/state/actions";
import { timeDifference } from "utils";


function Projects(props) {
  const { selectedProject, projects, setIsFullscreen, onNewProjectClick } = props;
  const dispatch = useDispatch();
  const profile = useSelector((store) => store.user.profile.payload);

  function chooseProjectClick(projectId) {
    dispatch(fetchProject(projectId));
    setIsFullscreen(false);
  }

  return (
    <S.FullscreenContainer onClickOutside={() => setIsFullscreen(false)}>
      <S.ProjectsContainer>
        {projects.map((project, index) => (
          <S.ProjectChooseButton key={index} onClick={(event) => chooseProjectClick(project.id)}>
            <S.ProjectNameContainer>
              <S.ProjectName>
                {project.name}
              </S.ProjectName>
              {selectedProject.id === project.id && <S.CheckedIcon />}
            </S.ProjectNameContainer>
            {profile.is_admin && 
              <S.ProjectCreatedAt>
                Created {timeDifference(new Date(), new Date(project.created_at))}
              </S.ProjectCreatedAt>
            }
          </S.ProjectChooseButton>
        ))}
      </S.ProjectsContainer>
      <S.ProjectChooseButton onClick={onNewProjectClick}>
        <S.NewProjectContainer>
          <S.PlusIcon /><S.ProjectName>New project</S.ProjectName>
        </S.NewProjectContainer>
      </S.ProjectChooseButton>
    </S.FullscreenContainer>
  );
}

export default Projects;
