//LIBRARIES
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../state/actions";
import Form from "./Form";
import { useNavigate } from "react-router-dom";

function SignIn(props) {
  const { toggleForgotPassword, toggleEmailVerification, redirect, disabled, ...others } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loading = useSelector((store) => store.user.auth.loading);
  
  function onSignInClick(username, password) {
    dispatch(login(username, password, () => navigate(redirect)));
  }

  return (
    <Form
      onSignInClick={onSignInClick}
      loading={loading}
      toggleForgotPassword={toggleForgotPassword}
      toggleEmailVerification={toggleEmailVerification}
      disabled={disabled}
    />
  );
}

export default SignIn;