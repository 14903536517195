import React, { useState } from "react";
import { Step, StepLabel } from "components/UI";
import { Divider } from "components/UI";
import SelectInterval from "./SelectInterval";
import Review from "./Review";
import { Header } from "components/UI/Common/Billing";
import PaymentMethod from "./PaymentMethod";
import { api } from "state/api";
//STYLEDCOMPONENTS
import * as S from "./style";

function PaymentSteps(props) {
  const { projectName, onClickOutside, plan, setPeriod, period, onSubscriptionSuccess, ...others } = props;
  const [activeStep, setActiveStep] = useState(0);
  const [primaryButtonDisabled, setPrimaryButtonDisabled] = useState(true);
  const [subscribing, setSubscribing] = useState(false);
  let confirmPayment = undefined;

  function setConfirmPaymentFunction(confirmPaymentFunction) {
    confirmPayment = confirmPaymentFunction;
  }

  const steps = [
    {
      name: "interval",
      component: <SelectInterval plan={plan} period={period} setPeriod={setPeriod}/>,
      label: "Billing interval",
      buttonLabel: "Select"
    },
    {
      name: "review",
      component: <Review plan={plan} period={period} setPrimaryButtonDisabled={setPrimaryButtonDisabled}/>,
      label: "Review",
      buttonLabel: "Continue to payment"
    },
    {
      name: "payment",
      component: <PaymentMethod projectName={projectName} planPathName={plan.pathName} planPeriod={period} setPrimaryButtonDisabled={setPrimaryButtonDisabled} onSuccess={onSubscriptionSuccess} setConfirmPaymentFunction={setConfirmPaymentFunction} onError={() => setSubscribing(false)}/>,
      label: "Payment",
      buttonLabel: "Subscribe",
      buttonDisabled: true
    }
  ]

  function subscribe(){
    console.log('subscribe');
    setSubscribing(true);
    confirmPayment();
  }
  
  const handleNext = () => {
    if(setPrimaryButtonDisabled === false){
      setPrimaryButtonDisabled(true);
    }

    if(steps[activeStep].name === "payment"){
      subscribe();
    }else{
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    if(setPrimaryButtonDisabled === false){
      setPrimaryButtonDisabled(true)
    }
    if(activeStep === 0){
      onClickOutside();
    }else{
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  return (
    <S.Modal onClickOutside={onClickOutside}>
      <Header color={plan.color} planName={plan.name}/>
      <S.Container>
        <S.Stepper activeStep={activeStep} small>
          {steps.map((step, index) => {
            let label = step.label
            if (activeStep >= index)
              return (
                <Step key={label} active={activeStep === index}>
                  <StepLabel active={activeStep === index} small>
                    {label}
                  </StepLabel>
                </Step>
              );
          })}
        </S.Stepper>
      </S.Container>
      <Divider />
     {steps[activeStep].component}
      <Divider />
      <S.ButtonContainer>
        <S.Button color="secondary" onClick={handleBack}>Back</S.Button>
        <S.Button disabled={steps[activeStep].buttonDisabled && primaryButtonDisabled} onClick={handleNext} loading={subscribing?1:undefined}>{steps[activeStep].buttonLabel}</S.Button>
      </S.ButtonContainer>
    </S.Modal>
  );
}

export default PaymentSteps;