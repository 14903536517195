//LIBRARIES
import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import { signUp } from '../state/actions';
import Form from './Form';
import { useNavigate } from "react-router-dom";

function SignUp(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loading = useSelector(store => store.user.auth.loading);
  
  function onSignUpClick(email, username, firstName, lastName, password1, password2, captchaToken) {
    dispatch(signUp(email, username, firstName, lastName, password1, password2, captchaToken, () => navigate('/authentication/signup/success')));
  }
 
  return (
    <Form onSignUpClick={onSignUpClick} loading={loading}/>
  );
}
 
export default SignUp;