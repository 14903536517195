//LIBRARIES
import React, { useState } from "react";

//STYLEDCOMPONENTS
import {
  StyledPrimaryButton as PrimaryButton,
  StyledInput as Input,
  StyledContainer as Container,
  StyledLegal as Legal,
  StyledLegalLink as LegalLink,
  //Or,
} from "./style";
import ReCAPTCHA from "react-google-recaptcha";
//import GoogleButton from './GoogleButton.js'
import WrongCredentials from "./WrongCredentials.js";
import { useSelector } from "react-redux";

function FormPresentational(props) {
  const {
    handleFieldChange,
    inputs,
    valid,
    handleOnSignUpClick,
    loading,
  } = props;
  const signInErrors = useSelector((store) => store.user.auth.error);
  var errors = [];
  const [captchaToken, setCaptchaToken] = useState();

  for (var errorKey in signInErrors) {
    for (var errorMessageKey in signInErrors[errorKey]) {
      errors.push(
        <WrongCredentials
          key={errorKey}
          signInError={signInErrors[errorKey][errorMessageKey]}
        />
      );
    }
  }

  function onCaptchaChange(value) {
    console.log("Captcha value:", value);
    setCaptchaToken(value);
  }

  return (
    <Container>
      {errors}
      <Input
        type="email"
        label={"Email"}
        id={"email"}
        onChange={handleFieldChange}
        input={inputs["email"]}
      />
      <Input
        type="username"
        label={"Username"}
        id={"username"}
        onChange={handleFieldChange}
        input={inputs["username"]}
      />
      <Input
        type="text"
        label={"First Name"}
        id={"firstName"}
        onChange={handleFieldChange}
        input={inputs["firstName"]}
      />
      <Input
        type="text"
        label={"Last Name"}
        id={"lastName"}
        onChange={handleFieldChange}
        input={inputs["lastName"]}
      />
      <Input
        type="password"
        label={"Password"}
        id={"password"}
        onChange={handleFieldChange}
        input={inputs["password"]}
      />
      <Input
        type="confirm-password"
        label={"Confirm Password"}
        id={"confirmPassword"}
        onChange={handleFieldChange}
        password={inputs["password"].value}
        input={inputs["confirmPassword"]}
      />
      <Legal>
        By signing up, you agree to our{" "}
        <LegalLink to={"/site/terms"} target="_blank">Terms</LegalLink> and{" "}
        <LegalLink to={"/site/privacy"} target="_blank">Privacy & Cookies Policy</LegalLink>
      </Legal>
      {valid &&
        <ReCAPTCHA
          sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
          onChange={onCaptchaChange}
        />
      }
      <PrimaryButton
        disabled={!valid || !captchaToken}
        loading={loading ? 1 : undefined}
        onClick={() => handleOnSignUpClick(captchaToken)}
      >
        Sign Up
      </PrimaryButton>
    </Container>
  );
}

export default FormPresentational;
