//LIBRARIES
import React from "react";

import { InputBase } from "components/UI";

function validateEmail(email) {
  //const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  return re.test(email);
}

function Email(props) {
  const { valid, setInput, label, showValidIcon, input, ...other } = props;

  function handleOnChange(event) {
    let validEmail = validateEmail(event.target.value);
    setInput({ value: event.target.value, valid: validEmail });
  }

  return (
    <InputBase
      type={"email"}
      valid={valid}
      label={label}
      value={input}
      handleOnChange={handleOnChange}
      showValidIcon={showValidIcon}
      {...other}
    />
  );
}

export default Email;

// "You will have to verify that you are the owner of this username account":
// "* Please enter a valid username."
