//LIBRARIES
import React from 'react';

//STYLEDCOMPONENTS
import { 
  StyledContainer as Container, 
} from "./style";

  
function ComplexSwitch(props) {

  return (
    <Container>

    </Container>
  );
}

export default ComplexSwitch;