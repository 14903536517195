//LIBRARIES
import React from 'react';


//STYLEDCOMPONENTS
import { 
  StyledContainer as Container, 
  StyledBase as Base, 
  StyledBubble1 as Bubble1,
  StyledBubble2 as Bubble2,
  //StyledBubble3 as Bubble3,
  StyledBubble4 as Bubble4,
  StyledShadow as Shadow,
  StyledBlurContainer as BlurContainer,
} from "./style";


function LogoBase(props) {
  return (
      <Base>
        <Bubble1/>
        <Bubble2/>
        {/* <Bubble3/> */}
        <Bubble4/>
        <Shadow/>
      </Base>
  );
}


function Logo(props) {
  const { size } = props;
  return (
    <Container size={size} > 
      <BlurContainer>
        <LogoBase/>
      </BlurContainer>
      <LogoBase/>
    </Container>
  );
}
 

export default Logo;