import * as Types from "./types";

const initialState = {
    loading:false,
    loaded:false,
    payload:{},
    error:''
}

function ProjectPlanReducer(state=initialState, action){
    switch(action.type){
        case Types.PROJECT_PLAN_INIT:
            return initialState;

        case Types.PROJECT_PLAN_REQUEST:
            return {
                loading:true,
                loaded:false,
                payload:{},
                error:''
            }

        case Types.PROJECT_PLAN_REQUEST_SUCCESS:
            return {
                loading:false,
                loaded:true,
                payload:action.payload.plan,
                error:''
            }

        case Types.PROJECT_PLAN_REQUEST_FAILURE:
            return {
                loading:false,
                loaded:false,
                payload:{},
                error:action.payload.error
            }
        default: return state
    }
}

export default ProjectPlanReducer;