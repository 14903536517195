import mixpanel from 'mixpanel-browser';
import axios from 'axios';

function getOS() {
    var userAgent = window.navigator.userAgent,
        platform = window.navigator.platform,
        macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
        windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
        iosPlatforms = ['iPhone', 'iPad', 'iPod'],
        os = null;

    if (macosPlatforms.indexOf(platform) !== -1) {
        os = 'Mac OS X';
    } else if (iosPlatforms.indexOf(platform) !== -1) {
        os = 'iOS';
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
        os = 'Windows';
    } else if (/Android/.test(userAgent)) {
        os = 'Android';
    } else if (!os && /Linux/.test(platform)) {
        os = 'Linux';
    }

    return os;
}

let location = {};
const os = {'$os':getOS()};


async function fetchLocation(){
    try{
        const response = await axios.get('https://geolocation-db.com/json/');
        return {
            'User IP': response.data.IPv4,
            '$city': response.data.city,
            '$region': response.data.state,
            'mp_country_code': response.data.country_code
        };
    }catch(error){
        return {};
    }
}

mixpanel.init(process.env.REACT_APP_MIXPANEL_PROJECT_TOKEN, { api_host: 'https://log.theos.ai' });

export async function track(event, properties = {}) {
    if(Object.keys(location).length === 0){
        location = await fetchLocation();
    }
    mixpanel.track(event, { ...properties, ...location, ...os })
}

export async function trackSignUp(username, firstName, lastName, email) {
    if(Object.keys(location).length === 0){
        location = await fetchLocation();
    }
    mixpanel.alias(username);
    mixpanel.people.set(username, {
        'Username': username,
        $first_name: firstName,
        $last_name: lastName,
        $email: email
    });
    mixpanel.track('Sign up');
}

export async function trackLogin(username) {
    if(Object.keys(location).length === 0){
        location = await fetchLocation();
    }
    mixpanel.identify(username);
    mixpanel.track('Log in', {...location, ...os});
}

export async function trackLogout() {
    if(Object.keys(location).length === 0){
        location = await fetchLocation();
    }
    mixpanel.track('Log out', {...location, ...os});
}