import { ReactComponent as IconUpload } from './upload.svg';
import { ReactComponent as IconUploadImage } from './upload-image.svg';
import { ReactComponent as IconMenu } from './menu.svg';
import { ReactComponent as IconLibrary } from './library.svg';
import { ReactComponent as IconTrophy } from './trophy.svg';
import { ReactComponent as IconHome } from './home.svg';
import { ReactComponent as IconInfo } from './info.svg';
import { ReactComponent as IconEye } from './eye.svg';
import { ReactComponent as IconEyeSlash } from './eye-slash.svg';
import { ReactComponent as IconSignOut } from './sign-out.svg';
import { ReactComponent as IconSettings } from './settings.svg';
import { ReactComponent as IconMonitor } from './monitor.svg';
import { ReactComponent as IconPlus } from './plus.svg';
import { ReactComponent as IconPlusSmall } from './plus-small.svg';
import { ReactComponent as IconGraph } from './graph.svg';
import { ReactComponent as IconFolder} from './folder.svg';
import { ReactComponent as IconTrash} from './trash.svg';
import { ReactComponent as IconClassification} from './classification.svg'; 
import { ReactComponent as IconSegmentation} from './segmentation.svg';
import { ReactComponent as IconBoundingBox} from './bounding-box.svg';
import { ReactComponent as IconMagicWand } from './magic-wand.svg';
import { ReactComponent as IconGym } from './gym.svg';
import { ReactComponent as IconAtom } from './atom.svg';
import { ReactComponent as IconTesting } from './testing.svg';
import { ReactComponent as IconMenuDotsVertical } from './menu-dots-vertical.svg'
import { ReactComponent as IconMenuDots } from './menu-dots.svg'
import { ReactComponent as IconCpu } from './cpu.svg'
import { ReactComponent as IconGpu } from './gpu.svg'
import { ReactComponent as IconAngleSmallDown } from './angle-small-down.svg'
import { ReactComponent as IconAngleSmallLeft } from './angle-small-left.svg'
import { ReactComponent as IconAngleSmallRight } from './angle-small-right.svg'
import { ReactComponent as IconAngleSmallUp } from './angle-small-up.svg'
import { ReactComponent as IconAngleDown } from './angle-down.svg'
import { ReactComponent as IconAngleLeft } from './angle-left.svg'
import { ReactComponent as IconAngleRight } from './angle-right.svg'
import { ReactComponent as IconAngleUp } from './angle-up.svg'
import { ReactComponent as IconAngleDoubleLeft } from './angle-double-left.svg'
import { ReactComponent as IconAngleDoubleRight } from './angle-double-right.svg'
import { ReactComponent as IconAngleDoubleSmallLeft } from './angle-double-small-left.svg'
import { ReactComponent as IconAngleDoubleSmallRight } from './angle-double-small-right.svg'
import { ReactComponent as IconArrowDown } from './arrow-down.svg'
import { ReactComponent as IconArrowLeft } from './arrow-left.svg'
import { ReactComponent as IconArrowRight} from './arrow-right.svg'
import { ReactComponent as IconArrowUp } from './arrow-up.svg'
import { ReactComponent as IconArrowSmallDown } from './arrow-small-down.svg'
import { ReactComponent as IconArrowSmallLeft } from './arrow-small-left.svg'
import { ReactComponent as IconArrowSmallRight} from './arrow-small-right.svg'
import { ReactComponent as IconArrowSmallUp } from './arrow-small-up.svg'
import { ReactComponent as IconUsers } from './users.svg'
import { ReactComponent as IconWorkInProgress } from './work-in-progress.svg';
import { ReactComponent as IconHand } from './hand.svg';
import { ReactComponent as IconUndoArrow } from './undo-arrow.svg';
import { ReactComponent as IconRedoArrow } from './redo-arrow.svg';
import { ReactComponent as IconSortDown } from './sort-down.svg';
import { ReactComponent as IconSortUp } from './sort-up.svg';
import { ReactComponent as IconGlobal } from './global.svg';
import { ReactComponent as IconMinusSmall } from './minus-small.svg';
import { ReactComponent as IconStats } from './stats.svg';
import { ReactComponent as IconUser } from './user.svg';
import { ReactComponent as IconFilter } from './filter.svg';
import { ReactComponent as IconEmpty } from './empty.svg';
import { ReactComponent as IconRocket } from './rocket.svg';
import { ReactComponent as IconCopy } from './copy.svg';
import { ReactComponent as IconCalendar } from './calendar.svg';
import { ReactComponent as IconRotateRight} from './rotate-right.svg';
import { ReactComponent as IconLine3horizontal} from './line-3-horizontal.svg';
import { ReactComponent as IconEdit} from './edit.svg';
import { ReactComponent as IconDownload} from './download.svg';
import { ReactComponent as IconZoomIn} from './zoom-in.svg';
import { ReactComponent as IconKeyboard} from './keyboard.svg';
import { ReactComponent as IconLeftClick} from './left-click.svg';
import { ReactComponent as IconSuperpixelAdd} from './superpixel-add.svg';
import { ReactComponent as IconSuperpixelRemove} from './superpixel-remove.svg';       
import { ReactComponent as IconSuperpixel} from './superpixel.svg';
import { ReactComponent as IconKey} from './key.svg';
import { ReactComponent as IconLock } from './lock.svg';

export default {
"menu" : IconMenu,
"upload" : IconUpload, 
"upload-image" : IconUploadImage,
"library" : IconLibrary,
"trophy" : IconTrophy,
"home" : IconHome,
"info" : IconInfo,
"eye":IconEye,
"eye-slash":IconEyeSlash,
"settings":IconSettings,
"sign-out":IconSignOut,
"monitor": IconMonitor,
"plus": IconPlus,
"plus-small": IconPlusSmall,
"graph": IconGraph,
"folder": IconFolder,
"trash": IconTrash,
"classification": IconClassification,
"segmentation": IconSegmentation,
"bounding-box": IconBoundingBox,
"magic-wand": IconMagicWand,
"gym": IconGym,
"atom": IconAtom,
"menu-dots-vertical": IconMenuDotsVertical,
"menu-dots": IconMenuDots,
"testing": IconTesting,
"gpu": IconGpu,
"cpu": IconCpu,
"angle-small-down": IconAngleSmallDown,
"angle-small-left": IconAngleSmallLeft,
"angle-small-right": IconAngleSmallRight,
"angle-small-up": IconAngleSmallUp,
"angle-down": IconAngleDown,
"angle-left": IconAngleLeft,
"angle-right": IconAngleRight,
"angle-up": IconAngleUp,
"angle-double-left": IconAngleDoubleLeft,
"angle-double-right": IconAngleDoubleRight,
"angle-double-small-left": IconAngleDoubleSmallLeft,
"angle-double-small-right": IconAngleDoubleSmallRight,
"arrow-down":IconArrowDown,
"arrow-left":IconArrowLeft,
"arrow-up":IconArrowUp,
"arrow-right":IconArrowRight,
"arrow-small-down":IconArrowSmallDown,
"arrow-small-left":IconArrowSmallLeft,
"arrow-small-up":IconArrowSmallUp,
"arrow-small-right":IconArrowSmallRight,
"users":IconUsers,
"work-in-progress":IconWorkInProgress,
"hand":IconHand,
"undo-arrow":IconUndoArrow,
"redo-arrow":IconRedoArrow,
"sort-down":IconSortDown,
"sort-up":IconSortUp,
"global": IconGlobal,
"minus-small":IconMinusSmall,
"stats":IconStats,
"user":IconUser,
"filter":IconFilter,
"empty":IconEmpty,
"rocket" : IconRocket,
"copy" : IconCopy,
"calendar" : IconCalendar,
"rotate-right" : IconRotateRight,
"line-3-horizontal":IconLine3horizontal,
"edit":IconEdit,
"download":IconDownload,
"zoom-in":IconZoomIn,
"keyboard":IconKeyboard,
"left-click":IconLeftClick,
"superpixel-add":IconSuperpixelAdd,
"superpixel-remove":IconSuperpixelRemove,
"superpixel":IconSuperpixel,
"key":IconKey,
"lock":IconLock
};


