import styled from "styled-components";
import { Button } from '@mui/material';
import { Loader } from "components/UI";
import Icon from "icons";

const ttime = 200;
const tfunction = "ease-in";

export const StyledLoader = styled(Loader).attrs((props) => ({
    width: 3,
    size: 20
}))`
position: absolute;
`;


export const StyledChildren = styled.div`
    visibility: ${props => props.loading && "hidden"};          
`;

export const StyledButton = styled(Button)`
border-radius: 10px;
color:${props => (props.theme.colors.text.default)};
/* DEFAULT BUTTON */
    && {
        position: relative;
        background-color: ${props => (props.theme.colors.button.default)};
        text-shadow: -0.1em -0.1em  0.15em ${props => (props.theme.colors.boxShadow.neumo.light)},
                    0.1em 0.1em  0.15em ${props => (props.theme.colors.boxShadow.neumo.contrast)};
        box-shadow: none;
        text-transform: none;                    
    }

    &:hover {
        box-shadow: -${props => (props.theme.effects.neumo.spread) / 2}px -${props => (props.theme.effects.neumo.spread) / 2}px  ${props => (props.theme.effects.neumo.blurLength)}px 0 ${props => (props.theme.colors.boxShadow.neumo.light)},
                    ${props => (props.theme.effects.neumo.spread) / 2}px ${props => (props.theme.effects.neumo.spread) / 2}px  ${props => (props.theme.effects.neumo.blurLength)}px 0 ${props => (props.theme.colors.boxShadow.neumo.contrast)};
    }

    &:active {
        box-shadow: -${props => (props.theme.effects.neumo.spread)}px -${props => (props.theme.effects.neumo.spread)}px  ${props => (props.theme.effects.neumo.blurLength)}px 0 ${props => (props.theme.colors.boxShadow.neumo.light)},
                    ${props => (props.theme.effects.neumo.spread)}px ${props => (props.theme.effects.neumo.spread)}px  ${props => (props.theme.effects.neumo.blurLength)}px 0 ${props => (props.theme.colors.boxShadow.neumo.contrast)},
                    inset 2px 2px 4px 0 ${props => (props.theme.colors.boxShadow.neumo.light)}, 
                    inset -2px -2px 4px 0 ${props => (props.theme.colors.boxShadow.neumo.contrast)};
        > * {
            transform:scale(0.95);
        }
    }

    &:focus {
        box-shadow: -${props => (props.theme.effects.neumo.spread)}px -${props => (props.theme.effects.neumo.spread)}px  ${props => (props.theme.effects.neumo.blurLength)}px 0 ${props => (props.theme.colors.boxShadow.neumo.light)},
                    ${props => (props.theme.effects.neumo.spread)}px ${props => (props.theme.effects.neumo.spread)}px  ${props => (props.theme.effects.neumo.blurLength)}px 0 ${props => (props.theme.colors.boxShadow.neumo.contrast)},
                    inset 2px 2px 4px 0 ${props => (props.theme.colors.boxShadow.neumo.light)}, 
                    inset -2px -2px 4px 0 ${props => (props.theme.colors.boxShadow.neumo.contrast)};
        > * {
            transform:scale(0.95);
        }
    }

/* VARIANT CONTAINED  / COLOR PRIMARY */
    &.MuiButton-containedPrimary {
    color: white;
    transition: box-shadow ${ttime}ms ${tfunction};
    background-image: ${props => (props.theme.colors.button.primary.gradient)};
    text-shadow: none;
    box-shadow: -${props => (props.theme.effects.neumo.spread)}px -${props => (props.theme.effects.neumo.spread)}px  ${props => (props.theme.effects.neumo.blurLength * 2)}px 0 ${props => (props.theme.colors.boxShadow.neumo.light)},
                ${props => (props.theme.effects.neumo.spread * 2)}px ${props => (props.theme.effects.neumo.spread * 2)}px  ${props => (props.theme.effects.neumo.blurLength) * 3}px 0 ${props => (props.theme.colors.boxShadow.primary.contrast)},
                inset 0px 0px 1px 0 ${props => (props.theme.colors.boxShadow.primary.insetlight)},
                inset -1px 1px 1px 0 ${props => (props.theme.colors.boxShadow.primary.insetContrast)};
    

                /* inset 2px 2px 4px 0 ${props => (props.theme.colors.boxShadow.primary.lightInset)}, 
                inset -2px -2px 4px 0 ${props => (props.theme.colors.boxShadow.primary.insetContrast)}; */

    }

    &.MuiButton-containedPrimary:hover {
    transition: box-shadow ${ttime}ms ${tfunction};
    box-shadow: -${props => (props.theme.effects.neumo.spread)}px -${props => (props.theme.effects.neumo.spread)}px  ${props => (props.theme.effects.neumo.blurLength)}px 0 ${props => (props.theme.colors.boxShadow.neumo.light)},
                ${props => (props.theme.effects.neumo.spread)}px ${props => (props.theme.effects.neumo.spread)}px  ${props => (props.theme.effects.neumo.blurLength)}px 0 ${props => (props.theme.colors.boxShadow.primary.contrast)},
                inset 1px -1px 0px 0 ${props => (props.theme.colors.boxShadow.primary.light)},
                inset -1px 1px 0px 0 ${props => (props.theme.colors.boxShadow.primary.insetContrast)};
        
    
    }

    &.MuiButton-containedPrimary:active {
    transition: box-shadow ${ttime}ms ${tfunction};
    box-shadow: 
    inset -${props => (props.theme.effects.neumo.spread / 2)}px -${props => (props.theme.effects.neumo.spread / 2)}px  ${props => (props.theme.effects.neumo.blurLength)}px 0 ${props => (props.theme.colors.boxShadow.primary.insetLight)},
    inset ${props => (props.theme.effects.neumo.spread / 2)}px ${props => (props.theme.effects.neumo.spread / 2)}px  ${props => (props.theme.effects.neumo.blurLength)}px 0 ${props => (props.theme.colors.boxShadow.primary.contrast)};
    }

    &.MuiButton-containedPrimary:focus {
    transition: box-shadow ${ttime}ms ${tfunction};
    box-shadow: 
    inset -${props => (props.theme.effects.neumo.spread / 2)}px -${props => (props.theme.effects.neumo.spread / 2)}px  ${props => (props.theme.effects.neumo.blurLength)}px 0 ${props => (props.theme.colors.boxShadow.primary.insetLight)},
    inset ${props => (props.theme.effects.neumo.spread / 2)}px ${props => (props.theme.effects.neumo.spread / 2)}px  ${props => (props.theme.effects.neumo.blurLength)}px 0 ${props => (props.theme.colors.boxShadow.primary.contrast)};

    }


/* VARIANT CONTAINED / COLOR SECONDARY */
    &.MuiButton-containedSecondary {
    color:${props => (props.theme.colors.text.classes)};
    background-color: ${props => (props.theme.colors.button.default)};
    
    box-shadow: -${props => (props.theme.effects.neumo.spread)}px -${props => (props.theme.effects.neumo.spread)}px  ${props => (props.theme.effects.neumo.blurLength)}px 0 ${props => (props.theme.colors.boxShadow.neumo.light)},
                ${props => (props.theme.effects.neumo.spread)}px ${props => (props.theme.effects.neumo.spread)}px  ${props => (props.theme.effects.neumo.blurLength)}px 0 ${props => (props.theme.colors.boxShadow.neumo.contrast)},
                inset 0px 0px 0px 0 ${props => (props.theme.colors.boxShadow.neumo.contrast)}, 
                inset 0px 0px 0px 0 ${props => (props.theme.colors.boxShadow.neumo.light)};}


                
    &.MuiButton-containedSecondary:hover {
    box-shadow: -1px -1px 1px ${props => (props.theme.colors.boxShadow.neumo.light)},
                1px 1px 1px ${props => (props.theme.colors.boxShadow.neumo.contrast)},
                inset 0px 0px 0px 0 ${props => (props.theme.colors.boxShadow.neumo.light)}, 
                inset 0px 0px 0px 0 ${props => (props.theme.colors.boxShadow.neumo.contrast)};

    }
    
    &.MuiButton-containedSecondary:active {
    box-shadow: inset -${props => (props.theme.effects.neumo.spread) / 2}px -${props => (props.theme.effects.neumo.spread) / 2}px  ${props => (props.theme.effects.neumo.blurLength) / 2}px 0 ${props => (props.theme.colors.boxShadow.neumo.light)},
                inset ${props => (props.theme.effects.neumo.spread) / 2}px ${props => (props.theme.effects.neumo.spread) / 2}px  ${props => (props.theme.effects.neumo.blurLength) / 2}px 0 ${props => (props.theme.colors.boxShadow.neumo.contrast)};
    }


    &.MuiButton-containedSecondary:focus {
    /* box-shadow: inset -${props => (props.theme.effects.neumo.spread) / 2}px -${props => (props.theme.effects.neumo.spread) / 2}px  ${props => (props.theme.effects.neumo.blurLength)}px 0 ${props => (props.theme.colors.boxShadow.neumo.light)},
                inset ${props => (props.theme.effects.neumo.spread) / 2}px ${props => (props.theme.effects.neumo.spread) / 2}px  ${props => (props.theme.effects.neumo.blurLength)}px 0 ${props => (props.theme.colors.boxShadow.neumo.contrast)}; */
    }


/* VARIANT CONTAINED / COLOR ERROR */
&.MuiButton-containedError {
    color: ${props => (props.theme.colors.text.error)};
    background-color: ${props => (props.theme.colors.button.default)};
    
    box-shadow: -${props => (props.theme.effects.neumo.spread)}px -${props => (props.theme.effects.neumo.spread)}px  ${props => (props.theme.effects.neumo.blurLength)}px 0 ${props => (props.theme.colors.boxShadow.neumo.light)},
                ${props => (props.theme.effects.neumo.spread)}px ${props => (props.theme.effects.neumo.spread)}px  ${props => (props.theme.effects.neumo.blurLength)}px 0 ${props => (props.theme.colors.boxShadow.neumo.contrast)},
                inset 0px 0px 0px 0 ${props => (props.theme.colors.boxShadow.neumo.contrast)}, 
                inset 0px 0px 0px 0 ${props => (props.theme.colors.boxShadow.neumo.light)};}


                
    &.MuiButton-containedError:hover {
    box-shadow: -1px -1px 1px ${props => (props.theme.colors.boxShadow.neumo.light)},
                1px 1px 1px ${props => (props.theme.colors.boxShadow.neumo.contrast)},
                inset 0px 0px 0px 0 ${props => (props.theme.colors.boxShadow.neumo.light)}, 
                inset 0px 0px 0px 0 ${props => (props.theme.colors.boxShadow.neumo.contrast)};

    }
    
    &.MuiButton-containedError:active {
    box-shadow: inset -${props => (props.theme.effects.neumo.spread) / 2}px -${props => (props.theme.effects.neumo.spread) / 2}px  ${props => (props.theme.effects.neumo.blurLength) / 2}px 0 ${props => (props.theme.colors.boxShadow.neumo.light)},
                inset ${props => (props.theme.effects.neumo.spread) / 2}px ${props => (props.theme.effects.neumo.spread) / 2}px  ${props => (props.theme.effects.neumo.blurLength) / 2}px 0 ${props => (props.theme.colors.boxShadow.neumo.contrast)};
    }




    
/* DISABLED */
    &.Mui-disabled {
    color: white;
    transition: box-shadow ${ttime}ms ${tfunction};
    color:${props => (props.theme.colors.text.light)};
    cursor: not-allowed;
    background-color: ${props => (props.theme.colors.button.disabled)};
    border: ${(props) => `1px solid ${props.theme.colors.border.default}`};
    background-image: none;
    text-shadow: none;
    box-shadow: 
            inset 2px 2px 5px 0 ${props => (props.theme.colors.boxShadow.neumo.contrast)}, 
            inset -2px -2px 5px 0 ${props => (props.theme.colors.boxShadow.neumo.light)};

    }
`;

export const StyledButtonIcon = styled(Icon).attrs((props) => ({
    size: "15px",
}))`
    margin-right: 10px;
`;