import styled from "styled-components";
import { Link } from "react-router-dom";

import {
  ScrollableDiv
} from "components/UI";
import hexToRgba from "hex-to-rgba";

const spread = 0;
const blurLength = 10;
const hoverRatio = 0.4;
const borderWidth = 2;
const borderRadius = 0;

export const StyledLink = styled(Link)`
  color:inherit;
  text-decoration: none;
`;




export const StyledTableRowBorder = styled.div`

  overflow: hidden;

  border-bottom: solid 1px ${props => (props.theme.colors.border.default)};

  margin-top: 0px;
  border-radius: ${borderRadius}px;

  &:hover {
    background-color: rgba(0,0,0,0.015)
  }
`;



export const StyledTableRowBase = styled.div`
    display: flex;
    flex-direction: row;
    border-radius: ${borderRadius - borderWidth}px;
    transition: box-shadow 200ms ease-in;
    cursor: pointer;
    height: 55px;
    width: 100%;
`;

export const StyledTableRow = styled(StyledTableRowBase)`


  &:hover {
    transition: box-shadow 200ms ease-in;
    box-shadow: ${spread}px ${spread}px ${blurLength}px
        rgba(0, 0, 0, 0.05),
      -${spread}px -${spread}px ${blurLength}px
        rgba(255, 255, 255, 0.50);
  }
`;


export const StyledTableRowSelected = styled(StyledTableRowBase)`
  && {
    box-shadow: ${(props) => `inset 25px 0 20px -25px ${hexToRgba(props.theme.colors.colorful.primary, 0.7)}`};
    border-left: ${(props) => "2px solid " + props.theme.colors.colorful.primary};
    background-color: ${(props) => hexToRgba(props.theme.colors.colorful.primary, 0.1)};
  
  }
`;



export const StyledTableCell = styled.div`
  && {
    flex: 1 1 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* text-align: left;
    overflow: hidden;
    white-space: nowrap; */
  }
`;

export const StyledTableBody = styled.div`
  && {
    color: ${(props) => props.theme.colors.text.default};
    font-weight: ${(props) => props.theme.fonts.weight.light};
    font-size: ${(props) => props.theme.fonts.body.m.size};
  }
`;

export const StyledTableHead = styled.div`
  && {
    display: flex;
    flex-direction: row;
    color: ${(props) => props.theme.colors.text.default};
    font-weight: ${(props) => props.theme.fonts.weight.medium};
    padding-top: 16px;
    padding-bottom: 16px;
    background-color: transparent;
    font-size: ${(props) => props.theme.fonts.title.s.size};
  }
`;


export const StyledTable = styled(ScrollableDiv)`
  padding: ${props => props.small? "2px" : "8px"};



  ${StyledTableRowBase} {
    ${props => props.small && `height: 40px;`};
  }

  ${StyledTableHead} {
    ${props => props.small && `font-size: ${props.theme.fonts.title.xs.size};`};
    ${props => props.small && ` padding-top: 8px;`};
    ${props => props.small && ` padding-bottom: 8px;`};
  }

  ${StyledTableBody} {
      ${props => props.small && `font-size: ${props.theme.fonts.body.s.size};`};
  }


`;
