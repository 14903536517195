//LIBRARIES
import React from 'react';

//STYLEDCOMPONENTS
import { 
  StyledContainer as Container, 
  StyledIcon as Icon,
  StyledLink as Link,
} from "./style";

  
function SocialButton(props) {
  const { name } = props;
  return (
    <Container>
      <Link 
      href={"https://twitter.com/devsnets"}
      target="_blank"
      rel="noopener noreferrer"
      >
      <Icon name={name}/>
      </Link>

    </Container>
  );
}
 
export default SocialButton;