import SearchIcon from "@mui/icons-material/Search";
import styled, { css, keyframes } from "styled-components";
import hexToRgba from "hex-to-rgba";
import { Skeleton } from "components/UI";

const iconMarginLeft = 12;
const borderWidth = 2;
const borderRadius = 4;
const iconSize = 24;

const animatedGradient = keyframes`
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
`;

export const StyledSearchIcon = styled(SearchIcon)`
  && {
    color: ${(props) => props.theme.colors.text.medium};
    height: ${iconSize}px;
    width: ${iconSize}px;
    margin-left: ${iconMarginLeft}px;
    margin-top: ${iconMarginLeft / 2}px;
    transform: translateY(2px)
  }
`;

// margin-top: 0px;
// margin-bottom: 0px;

export const StyledBorder = styled.div`

width: 100%;
height: 45px;

padding: ${borderWidth}px;
border-radius: ${borderRadius + borderWidth}px;


background: ${(props) =>
  props.focused
    ? `linear-gradient(60deg, rgba(220,220,220,1), rgba(177, 181, 254,0.6), rgb(153, 206, 255,0.5),rgba(177, 181, 254,0.4), rgba(220, 220, 220,1))`
    : ""};




  

animation: ${(props) =>
  props.focused
    ? css`
        ${animatedGradient} 6s infinite alternate
      `
    : ""};

transition: background-color 0.3s ease-in-out;
background-size: 300% 300%;
`;

export const StyledInput = styled.input`

width: 95%;
-webkit-appearance: none;


font-size: ${(props) => props.theme.fonts.title.s.size};


// border-radius: ${borderRadius}px;

transition: background-color 0.3s ease-in-out, transform 0.3s ease-in-out;
background-color: transparent;




&::placeholder {
  color: ${(props) => props.theme.colors.text.medium};
}

`;

export const StyledDiv = styled.div`
  // background-color: rgba(0, 0, 0, 0.025);
  position: relative;



   background-color: ${(props) => props.focused ? "white" : props.theme.colors.input.default};
  

  &:after {
    content: "";
    pointer-events: none;
    position: absolute;
    width: 100%;
    height: 100%;
  }

  border-bottom: 2px solid white;

  border-bottom: ${(props) => props.focused ? '2px solid transparent' :'2px solid white'};


 


  display: grid;
  grid-template-columns: 40px 1fr;
  width: 100%;
  height: 100%;
 

  /* border-radius: ${borderRadius}px; */
  border-radius: ${borderRadius}px;

  transition: box-shadow 0.3s ease-in-out, transform 0.3s ease-in-out, background-color 0.3s ease-in-out;
`;

export const StyledSkeleton = styled(Skeleton)`
  height: 40px;
`;
