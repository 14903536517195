  
import React from 'react';
import { propTypes } from './Icon.props';
import iconMap from 'assets/svgs/icon-map';

const EmptyIcon = () => <div/>;

// const AlertIcon = React.forwardRef((props, ref) => (
//   <Icon name="solid alert" size="12px" color="orange" {...props} ref={ref}/>
// ));

const Icon = React.forwardRef(function Icon(props, ref) {
  //  Spread the props to the underlying DOM element.
  const { name, size, color, ...rest } = props;
  
  const Icon = iconMap[name] || EmptyIcon;
  return (
    <Icon style={{ fill:color, width: size, height: size }} {...rest} ref={ref} />
  );
});


// const Icon = ({ name, size, color, ...rest }) => {
//   const Icon = iconMap[name] || EmptyIcon;
//   return <Icon style={{ fill:color, width: size, height: size }} {...rest} />;
// };

// Icon.propTypes = propTypes;

// Icon.defaultProps = {
//   size: '2.4em',
//   //color: theme.colors.text.default,
// };

export default Icon;