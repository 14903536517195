export const CLASSNAMES_ADD = 'CLASSNAMES_ADD';
export const CLASSNAMES_ADD_FAILURE = 'CLASSNAMES_ADD_FAILURE';
export const CLASSNAMES_ADD_SUCCESS = 'CLASSNAMES_ADD_SUCCESS';
export const DEPLOYMENT_SET = 'DEPLOYMENT_SET';
export const IMAGE_DATASET_TOOL_INFO_REQUEST = 'IMAGE_DATASET_TOOL_INFO_REQUEST';
export const IMAGE_DATASET_TOOL_INFO_REQUEST_FAILURE = 'IMAGE_DATASET_TOOL_INFO_REQUEST_FAILURE';
export const IMAGE_DATASET_TOOL_INFO_REQUEST_SUCCESS = 'IMAGE_DATASET_TOOL_INFO_REQUEST_SUCCESS';
export const IMAGE_DATASET_TOOL_INIT = 'IMAGE_DATASET_TOOL_INIT';
export const OPEN_SHORTCUTS_PANEL_TOGGLE = 'OPEN_SHORTCUTS_PANEL_TOGGLE ';
export const SET_BRUSH_SIZE = 'SET_BRUSH_SIZE';
export const SET_CANVAS_SIZE = 'SET_CANVAS_SIZE';
export const SET_CLASSNAME_VISIBLE = 'SET_CLASSNAME_VISIBLE';
export const SET_COLOR = 'SET_COLOR';
export const SET_CONTEXT_MENU = 'SET_CONTEXT_MENU';
export const SET_CURSOR = 'SET_CURSOR';
export const SET_ERASE_MODE = 'SET_ERASE_MODE';
export const SET_LOCKED_MODE = 'SET_LOCKED_MODE';
export const SET_MODE = 'SET_MODE';
export const SET_SELECTED_CLASSIFICATION_CLASSNAME = 'SET_SELECTED_CLASSIFICATION_CLASSNAME';
export const SET_SELECTED_CLASSNAME = 'SET_SELECTED_CLASSNAME';
export const SET_SHOW_NAMES = 'SET_SHOW_NAMES';
export const SET_SUPERPIXEL_SIZE = 'SET_SUPERPIXEL_SIZE';
export const SET_TOOL = 'SET_TOOL';
export const SET_ZOOM_SENSITIVITY = 'SET_ZOOM_SENSITIVITY';
export const SHORTCUTS_SET = 'SHORTCUTS_SET';
export const SHORTCUTS_TOGGLE = 'SHORTCUTS_TOGGLE';
export const TOGGLE_IS_DRAWING = 'TOGGLE_IS_DRAWING';
export const CLASS_SKELETON_SET = 'CLASS_SKELETON_SET';