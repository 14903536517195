//LIBRARIES
import React, { useState, useEffect } from "react";
//STYLEDCOMPONENTS
import {
  StyledResendEmailButton as ResendEmailButton,
  StyledResendEmailLinkButton as ResendEmailLinkButton,
  StyledForgotPasswordButton as ForgotPasswordButton,
  StyledInput as Input,
  StyledContainer as Container,
} from "./style";
import WrongCredentials from "./WrongCredentials.js";
import { PrimaryActionButton } from 'components/UI';


function FormPresentational(props) {
  const {
    valid,
    inputs,
    handleOnSignInClick,
    handleFieldChange,
    toggleForgotPassword,
    toggleEmailVerification,
    signInErrors,
    loading,
    disabled
  } = props;
  const [notVerified, setNotVerified] = useState(false);
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    const loginErrors = [];
    for(var errorKey in signInErrors){
      for(var errorMessageKey in signInErrors[errorKey]){
        const message = signInErrors[errorKey][errorMessageKey];
        loginErrors.push(
          <WrongCredentials key={errorKey} signInError={message}/>
        )
        if(message.includes('not verified')){
          setNotVerified(true);
        }
      }
    }
    setErrors(loginErrors);
  }, [signInErrors]);

  //Find your Theos account
  return (
      <Container>
        {errors}
        {notVerified &&
          <ResendEmailButton onClick={toggleEmailVerification}>
            Resend Account Verification Email
          </ResendEmailButton>
        }
        <Input
          type="username"
          label={"Username"}
          id={"username"}
          onChange={handleFieldChange}
          input={inputs["username"]}
          focus={true}
        />
        <Input
          type="password"
          label={"Password"}
          id={"password"}
          onChange={handleFieldChange}
          input={inputs["password"]}
        />
        <ResendEmailLinkButton onClick={toggleEmailVerification}>
          Resend verification email
        </ResendEmailLinkButton>
        <ForgotPasswordButton onClick={toggleForgotPassword}>
          Forgot Password?
        </ForgotPasswordButton>
        {!disabled && 
          <PrimaryActionButton disabled={!valid} loading={loading ? 1 : undefined} onClick={handleOnSignInClick}>
            Login
          </PrimaryActionButton>
        }
      </Container>
  );
}

export default FormPresentational;
