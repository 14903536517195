import React, { useState, useEffect } from "react";
//STYLEDCOMPONENTS
import * as S from "./style";
import ParallaxPlan from "./ParallaxPlan"
import PaymentSteps from "../PaymentSteps";
import PaymentSuccessful from "../PaymentSuccessful";


function SelectPlan(props) {
  const { projectName, onClickOutside, title, description, onSubscriptionSuccess, ...others } = props;
  const [period, setPeriod] = useState('yearly');
  const [success, setSuccess] = useState(false);
  let plans = {
    startup: {
      color: "red",
      name: "Startup",
      pathName: "startup",
      price: {
        month_yearly: 299,
        year: 3599,
        month: 359,
      },
      description: "Up to 5 datasets, 25k images, 3 team members, 45 hours/month of cloud GPU training (then $0.96/hour), and 5k deployment requests/month (then $0.01/request)."
    },
    business: {
      color: "purple",
      name: "Business",
      pathName: "business",
      price: {
        month_yearly: 599,
        year: 6999,
        month: 699
      },
      description: "Up to 20 datasets, 100k images, 10 team members, 150 hours/month of cloud GPU training (then $0.96/hour), and 20k deployment requests/month (then $0.01/request)."
    },
    enterprise: {
      color: "diamond",
      name: "Enterprise",
      description: "Talk to our team of AI experts. Tell us about your use case and we will give you volume discounts on a custom plan that delivers on your specific enterprise requirements."
    }
  }
  const [selectedPlan, setSelectedPlan] = useState();


  function togglePeriod() {
    setPeriod(period === 'yearly' ? 'monthly' : 'yearly');
  }

  function onSuccess() {
    setSuccess(true);
  }

  function onSuccessOutsideClick() {
    setSuccess(false);
    onSubscriptionSuccess();
  }

  return (
    <S.Modal onClickOutside={onClickOutside}>
      {selectedPlan && <PaymentSteps projectName={projectName} plan={selectedPlan} period={period} setPeriod={setPeriod} onClickOutside={() => setSelectedPlan()} onSubscriptionSuccess={onSuccess} />}
      {selectedPlan && success && <PaymentSuccessful plan={selectedPlan} onClickOutside={onSuccessOutsideClick} />}
      <S.Container>
      <S.Title>
        {title}
      </S.Title>
      <S.Description>
        {description}
      </S.Description>
      <S.SwitchContainer>
        <S.Switch withLed={true} checked={period === 'yearly'} onChange={togglePeriod} />
        {period === 'yearly' ?
          <S.SwitchTitleContainer>
            <S.SwitchTitle>Yearly plan</S.SwitchTitle>
            <S.SwitchTitleDiscount>at 16% discount</S.SwitchTitleDiscount>
          </S.SwitchTitleContainer>
          :
          <S.SwitchTitle>Monthly plan</S.SwitchTitle>
        }
      </S.SwitchContainer>
      <S.PlansContainer>
        {<ParallaxPlan plan={plans.startup} period={period} onClick={() => setSelectedPlan(plans.startup)} />}
        {<ParallaxPlan plan={plans.business} period={period} onClick={() => setSelectedPlan(plans.business)} />}
        {<ParallaxPlan plan={plans.enterprise} period={period} onClick={() => { window.location = 'mailto:contact@theos.ai' }} />}
      </S.PlansContainer>
    </S.Container>
    </S.Modal>
  );
}

export default SelectPlan;
