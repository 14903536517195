

export const gray = {
    // 50: '#f8fafc',
    // 100: '#f1f5f9',
    // 200: '#e2e8f0',
    // 300: '#cbd5e1',
    // 400: '#94a3b8',
    // 500: '#64748b',
    // 600: '#475569',
    // 700: '#334155',
    // 800: '#1e293b',
    // 900: '#0f172a',

    50: '#f9fafb',
    100: '#f3f4f6',
    200: '#e5e7eb',
    300: '#d1d5db',
    400: '#9ca3af',
    500: '#6b7280',
    600: '#4b5563',
    700: '#374151',
    800: '#1f2937',
    900: '#111827',
}

export const neumo = {
    // default: '#F1F3F6',
    default: gray['100'],
    disabled: '#E9EBEE',
    // hover:'#ebedf0',
    hover:gray['200'],
    text: gray['900'],
    medium: gray['500'],
    lightText: gray['400'],
    ultraLight: gray['300'],
    input: gray['200']
}

export const led = {
    green: '#00ebb8',
}

// export const gradient = {
//     left: '#A1CCFF',
//     right: '#A7C0FF',
// }

// export const gradient = {
//     left: '#FFB3B3',
//     right: '#FECAB4',
// }

export const gradient = {
    left: '#B1B5FE',
    right: '#99ceff'
}

export const borderGradient = {
    left: '#FFFFFF',
    //right: '#E9EBED',
    right: '#E9EBED',
    default: '#DEDFE0'
    
}

export const greenGradient = {
    left: '#3bebc0',
    right: '#1feae4',
}

export const white = {
    default: '#FFFFFF',
}

export const black = {
    default: '#000000',
}

export const purple = {
    default:'#8690E4',
    light:'#9A81FF',
    medium: '#7d7cfc',
    dark:'#6363ff',
    darker:'#4949ba'
}

export const success = {
    default: '#00D4A1',
    light: '#9AFFED',
    ultraLight: '#E0F5F4',
}

export const error = {
    default: '#ff7878',
    light: '#ffbaba',
}

export const alert = {
    default: '#FBA12E',
    light: '#fff0de',
}

export const classes = {
    default: '#8591ff',
}

export const colorful = {
    primary: '#9494ff',
    secondary: "#9494ff", 
    disabled: '#ceceed'
}