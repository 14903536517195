import styled  from "styled-components";
import Icon from "icons";

export const StyledContainer = styled.div`

`;

export const StyledLabel = styled.label`
  font-size: ${(props) => props.theme.fonts.title.xs.size};
  font-weight: ${(props) => props.theme.fonts.weight.bold};
  color: ${(props) => props.valid ? props.theme.colors.text.neumo : props.theme.colors.text.error};
`;

export const StyledIcon = styled(Icon).attrs((props) => ({
size: '100%'
}))`

/* filter: ${(props) => props.valid ? `drop-shadow(0px 0px 3px ${props.theme.colors.text.success})` : `drop-shadow(0px 0px 3px ${props.theme.colors.text.error})`}; */
color: ${(props) => props.valid ? props.theme.colors.text.success : props.theme.colors.text.error};
filter: drop-shadow(-2px -2px 1px white) drop-shadow(2px 2px 1px rgba(0,0,0,0.1));

`;


export const StyledLabelContainer = styled.div`
height: ${(props) => `calc( ${props.theme.fonts.title.xs.size} * 2.5)`};
display: flex;
justify-content: space-between;
align-items: center;
`;

export const StyledLabelBottomContainer = styled.div`
/* height: ${(props) => props.showBottomLabel ? `calc( ${props.theme.fonts.title.xs.size * 3} )`: '0px'}; */
height: ${(props) => props.showBottomLabel ? `calc( ${props.theme.fonts.title.xs.size} * 3.25 )` : '0px'};
transition: height 200ms ease-in-out;
display: flex;
/* background-color: blue; */
/* display:${(props) => (props.valid !== null && props.valid && (!props.focused)) && "none"};   */
justify-content: space-between;
align-items: center;
`;

export const StyledLabelBottom = styled.label`
  font-size: ${(props) => props.theme.fonts.title.xs.size};
  /* background-color: red; */
  font-weight: ${(props) => props.theme.fonts.weight.regular};
  color: ${(props) => props.valid ? props.theme.colors.text.medium : props.theme.colors.text.error};
`;


export const StyledIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  /* box-shadow: -3px -3px 4px 0px white, 2px 2px 2px 0px rgba(0,0,0,0.05); */

  border-radius: 20px;
  height: 18px;
  width: 18px;
`;


// export const StyledInput2 = styled.input`
//   height: 40px;
//   -webkit-appearance: none;
//   width: 100%;
//   font-size: ${(props) => props.theme.fonts.title.xs.size};
//   padding: 14px 20px;
//   box-shadow: 
//     ${(props) =>
//       props.valid
//         ? `
//     inset -${props.theme.effects.neumo.spread / 2}px 
//     -${props.theme.effects.neumo.spread / 2}px
//       ${props.theme.effects.neumo.blurLength}px 0
//       ${props.theme.colors.boxShadow.neumo.light},
//      inset ${props.theme.effects.neumo.spread / 2}px
//       ${props.theme.effects.neumo.spread / 2}px
//       ${props.theme.effects.neumo.blurLength}px 0
//       ${props.theme.colors.boxShadow.neumo.contrast};
//     `
//         : `inset 0px 0px 5px 0px ${props.theme.colors.text.error}`};

//   background-color: ${(props) => props.theme.colors.bg.default};

//   border-radius: ${borderRadius}px;
//   border: ${(props) =>
//     props.valid && `1px solid ${props.theme.colors.bg.default}`};

//   transition: box-shadow 0.3s ease-in-out, transform 0.3s ease-in-out;


//   &:focus-within {
//     background-color: ${(props) =>
//       props.valid
//         ? props.theme.colors.bg.default
//         : props.theme.colors.div.secondary.error.bg};
//     box-shadow: 
//      ${(props) =>
//        props.valid
//          ? `inset -${props.theme.effects.neumo.spread / 2}px 
//        -${props.theme.effects.neumo.spread / 2}px
//         ${props.theme.effects.neumo.blurLength}px 0
//         ${props.theme.colors.boxShadow.neumo.light},
//       inset ${props.theme.effects.neumo.spread / 2}px
//         ${props.theme.effects.neumo.spread / 2}px
//         ${props.theme.effects.neumo.blurLength}px 0
//         ${props.theme.colors.boxShadow.neumo.contrast}`
//          : `inset 0px 0px 5px 0px ${props.theme.colors.text.error}`};

//     transform: scale(1.0005);
//   }
// `;
