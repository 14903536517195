import styled from "styled-components";
import { Button as ButtonBase, IconButton as IconButtonBase} from "@mui/material";
import { Fullscreen } from "components/UI";

const aspectRatio = 16 / 9;
const borderRadius = 10;
const widthPercentageDesktop = 60;
const paddingBottomPercentageDesktop =
  Math.pow(aspectRatio, -1) * widthPercentageDesktop;
const widthPercentageMobile = 90;
const paddingBottomPercentageMobile =
  Math.pow(aspectRatio, -1) * widthPercentageMobile;

export const VideoContainer = styled.div`
  && {
    border-radius: ${borderRadius}px;
    width: ${widthPercentageDesktop}vw;
    padding-bottom: ${paddingBottomPercentageDesktop}vw; /* 16:9, for an aspect ratio of 1:1 change to this value to 100% */

    @media (max-width: ${(props) => props.theme.breakpoints.mobile.max}px) {
      width: ${widthPercentageMobile}vw;
      padding-bottom: ${paddingBottomPercentageMobile}vw;
    }
  }
`;

export const Button = styled(ButtonBase)`
  && {
    min-width: 70px;
    backdrop-filter: blur(20px);
    width: 7%;
    height: 40px;
    border-radius: 10px;
    color: white;
    background-color: rgba(0, 0, 0, 0.4);
    font-weight: ${(props) => props.theme.fonts.weight.light};
    box-shadow: none;
  }
`;

export const IconButton = styled(IconButtonBase)`
  position: absolute;
  color: white;
  top: 50%;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile.max}px) {
    top: 100%;
  }

  &:hover {
    color: ${(props) => props.theme.colors.text.classes};
  }
`;

export const NextButton = styled(IconButton)`
  && {
    right: -50px;
    @media (max-width: ${(props) => props.theme.breakpoints.mobile.max}px) {
      right: 0px;
    }
  }
`;

export const PreviousButton = styled(IconButton)`
  && {
    left: -50px;
    @media (max-width: ${(props) => props.theme.breakpoints.mobile.max}px) {
      left: 0px;
    }
  }
`;

export const Quantity = styled.div`
  && {
    position: absolute;
    font-size: ${(props) => props.theme.fonts.title.s.size};
    bottom: -30px;
    width: 100%;
    color: white;
    text-align: center;
  }
`;

export const Container = styled.div`
  height: calc(100% + 1px);
  border-radius: ${borderRadius}px;
  position: absolute;
  overflow: hidden;
  width: 100%;
`;


export const FullscreenContainer = styled(Fullscreen)`
  && {
    overflow: hidden;
    min-height: 400px;
    min-width: 400px;
  }
`;